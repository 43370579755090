// ShopBannerApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const ShopBannerApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Add an interceptor to include the token in the Authorization header for all requests
ShopBannerApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your shop banner-related API endpoints
export const getShopBanners = async () => {
  try {
    const response = await ShopBannerApi.get("/shopBanner");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getShopBannerById = async (id) => {
  try {
    const response = await ShopBannerApi.get(`/shopBanner/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getShopBannerByShopId = async (id) => {
  try {
    const response = await ShopBannerApi.get(`/shopBanner/${id}/shop`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createShopBanner = async (bannerData) => {
  try {
    const response = await ShopBannerApi.post("/shopBanner", bannerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateShopBanner = async (id, bannerData) => {
  try {
    const response = await ShopBannerApi.put(`/shopBanner/${id}`, bannerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteShopBanner = async (id) => {
  try {
    const response = await ShopBannerApi.delete(`/shopBanner/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other shop banner-related API endpoints as needed

export default ShopBannerApi;
