import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader(props) {
  const { isLoading } = props;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box sx={{ height: 40 }}>
        {isLoading ? (
          <Fade in={isLoading} unmountOnExit>
            <CircularProgress />
          </Fade>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
