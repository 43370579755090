import { Button } from "@mui/material";
import React from "react";
import { deleteShop } from "../../../API/ShopApi";
import { toast } from "react-toastify";
const Delete = (props) => {
  const erase = async () => {
    deleteShop(props.id)
      .then((response) => {
        if (response.status == true) {
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <>
      <Button onClick={erase} color="error">
        Delete Shop
      </Button>
    </>
  );
};

export default Delete;
