import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Cancel } from "@mui/icons-material";

export default function Variants(props) {
  const { price, size, color, id, onRemove } = props;

  const handleRemove = () => {
    onRemove(id); // Call the parent's remove function with variant id
  };

  return (
    <Box style={{ position: "relative", padding: "20px" }}>
      <Card
        sx={{
          height: "50px",
          width: "220px",
          marginBlock: "10px"
        }}>
        <CardContent
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 !important"
          }}>
          <Box ml="10px">
            <Typography>
              <b>Size</b>
            </Typography>
            <Typography>{size}</Typography>
          </Box>
          <Box>
            <Typography>
              <b>Price</b>
            </Typography>
            <Typography>{price}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: `${color}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
              width: "50px"
            }}>
            <Box>
              <Typography>
                <b>Color</b>
              </Typography>
              {color}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box style={{ position: "absolute", top: "10px", right: "0px" }}>
        <IconButton onClick={handleRemove}>
          <Cancel color="red" />
        </IconButton>
      </Box>
    </Box>
  );
}
