/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
import { Cropper } from "react-advanced-cropper";
import "./test.css"; // Import CSS file for component styling
import Pic01 from "./resources/Pic-01.jpg";
import Pic02 from "./resources/Pic-02.jpg";
import Pic03 from "./resources/Pic-03.png";
import Pic04 from "./resources/Pic-04.jpg";
import Pic05 from "./resources/Pic-05.jpg";
import { debounce } from "lodash";

const TestComponent = ({
  images: propImages,
  setImages: setPropImages,
  onSaveImages
}) => {
  const image = [Pic01, Pic02, Pic03, Pic04, Pic05];
  const [images, setImages] = useState(image || []);
  const [savedImages, setSavedImages] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imgRatio, setImgRatio] = useState(null);
  const cropperRef = useRef(null);

  //   useEffect(() => {
  //     setImages(propImages || []);
  //     setActiveImageIndex(0);
  //   }, [propImages]);

  const debouncedOnChange = useRef(
    debounce((cropper) => {
      const croppedCanvas = cropper.getCanvas();
      if (croppedCanvas) {
        const croppedImageUrl = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageUrl);
      }
    }, 300) // Debounce time in milliseconds
  ).current;

  const onChange = (cropper) => {
    debouncedOnChange(cropper);

    // const croppedCanvas = cropper.getCanvas();
    // if (croppedCanvas) {
    //   const croppedImageUrl = croppedCanvas.toDataURL();
    //   setCroppedImage(croppedImageUrl);
    // }
  };

  const handleSave = () => {
    if (croppedImage) {
      const croppedBlob = dataURLtoBlob(croppedImage);
      const croppedImageObj = { preview: croppedImage, raw: croppedBlob };
      setSavedImages([...savedImages, croppedImageObj]);
      setCroppedImage(null);
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleSetImages = () => {
    onSaveImages(savedImages);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveImageIndex((prevIndex) =>
  //       prevIndex >= images.length - 1 ? 0 : prevIndex + 1
  //     );
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [images.length]);

  return (
    <div className="combined-slider-cropper-container">
      {/* Image Slider */}
      <div className="image-slider-container">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            style={{ maxWidth: "300px", margin: "10px" }}
            alt={`Image ${index + 1}`}
            className={index === activeImageIndex ? "active" : ""}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>

      {/* Cropper */}
      <div className="cropper-container">
        {images.length > 0 && (
          <Cropper
            style={{ maxWidth: "30vh" }}
            src={images[activeImageIndex]}
            onChange={onChange}
            className={"cropper"}
            ref={cropperRef}
            aspectRatio={imgRatio}
          />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => setImgRatio(3 / 3)}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "#FA2A55",
              borderRadius: "10px",
              border: "12px white",
              color: "white",
              minWidth: "70px"
            }}
          >
            3:3
          </button>
          <button
            onClick={() => setImgRatio(16 / 6)}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "#FA2A55",
              borderRadius: "10px",
              border: "12px white",
              color: "white",
              minWidth: "70px"
            }}
          >
            16:6
          </button>
          <button
            onClick={() => setImgRatio(null)}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "#FA2A55",
              borderRadius: "10px",
              border: "12px white",
              color: "white"
            }}
          >
            Custom
          </button>
        </div>
      </div>

      {/* Cropped Image Preview */}
      <div className="cropped-image-preview">
        {croppedImage && (
          <>
            <h2>Cropped Image Preview</h2>
            <img
              src={croppedImage}
              style={{ maxWidth: "30vh", height: "auto" }}
              alt="Cropped"
            />
            <button
              onClick={handleSave}
              style={{
                padding: "10px",
                margin: "10px",
                backgroundColor: "#FA2A55",
                borderRadius: "10px",
                border: "12px white",
                color: "white"
              }}
            >
              Save Cropped Image
            </button>
          </>
        )}
        {!croppedImage && <p>No image cropped yet</p>}
      </div>

      {/* Saved Images Preview */}
      <div className="saved-images-preview">
        <h3>Saved Images Preview</h3>
        {savedImages.map((image, index) => (
          <img
            key={index}
            src={image.preview}
            alt={`Saved Image ${index + 1}`}
            style={{ maxWidth: "50px", marginRight: "10px" }}
          />
        ))}
        <button
          onClick={handleSetImages}
          style={{
            padding: "10px",
            margin: "10px",
            backgroundColor: "#FA2A55",
            borderRadius: "10px",
            border: "12px white",
            color: "white"
          }}
        >
          Set Images
        </button>
      </div>
    </div>
  );
};

export default TestComponent;
