// CategoryApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";
import { toast } from "react-toastify";
const baseURL = backendApiUrl;

const CategoryApi = axios.create({
  baseURL,
});

// // Add an interceptor to include the token in the Authorization header for all requests
// CategoryApi.interceptors.request.use(
//   (config) => {
//     const authToken = store.getState().auth.authToken;
//     if (authToken) {
//       config.headers.Authorization = `${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Define your category-related API endpoints
export const getProductCategories = async () => {
  try {
    const response = await CategoryApi.get("/productCategory");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProductCategory = async (categoryData) => {
  try {
    const response = await CategoryApi.post(`/productCategory/`, categoryData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await CategoryApi.delete(`/productCategory/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editCategory = async (categoryData, id) => {
  try {
    const response = await CategoryApi.put(
      `/productCategory/${id}`,
      categoryData
    );
    toast.success("Category Updated Successfully");
    return response.data;
  } catch (error) {
    toast.error("Category Update Failed", error);
    throw error;
  }
};

// Add other category-related API endpoints as needed

export default CategoryApi;
