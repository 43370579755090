import React, { useEffect, useState } from "react";
import { getEnquiries } from "../../API/EnquiryApi";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const Enquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const fetchEnquiries = async () => {
    const response = await getEnquiries();
    setEnquiries(response.data);
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "--DataGrid-containerBackground": "#D3D3D3", // Custom dark shade for header background
            "--unstable_DataGrid-headWeight": "bold" // Bold font for header
          }
        }
      }
    }
  });

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const columns = [
    { field: "user", headerName: "User", width: 200 },
    { field: "enquiryTitle", headerName: "Enquiry", width: 150 },
    // { field: 'description', headerName: 'Enquiry Description', width: 250 },
    {
      field: "enquiryAt",
      headerName: "Enquiry Time",
      width: 100,
      type: "date"
    },
    { field: "productName", headerName: "Product Name", width: 200 },
    { field: "shopName", headerName: "Shop Name", width: 200 },
    { field: "kartaName", headerName: "Shop Owner", width: 250 }
  ];

  const rows = enquiries.map((enquiry, index) => ({
    id: enquiry._id,
    user:
      enquiry?.userId?.firstName + "  " + "(" + enquiry?.userId?.mobile + ")",
    enquiryTitle: enquiry?.title,
    // description: enquiry?.description,
    enquiryAt: new Date(enquiry?.createdAt),
    productName: enquiry?.productId?.name,
    shopName: enquiry?.shopId?.name,
    kartaName:
      enquiry?.shopId?.kartaName + "  " + "(" + enquiry?.shopId?.mobile + ")"
  }));

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <h1 style={{ textAlign: "center" }}>User Enquiries</h1>
        {rows.length > 0 && (
          <DataGrid
            style={{ width: "90%", margin: "auto" }}
            rows={rows}
            sx={{
              m: 2
            }}
            columns={columns}
            pageSize={5}
            disableRowSelectionOnClick
            getRowId={(row) => row.id}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default Enquiries;
