import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { createReels } from "../../API/ReelsApi";
import axios from "axios";
import { backendApiUrl } from "../../constants";
import { toast } from "react-toastify";

export default function ShopReel() {
  const { shopId } = useParams();
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onVidChange = (e) => {
    const selectedFile = e.target.files[0];
    setVideo(selectedFile);
  };

  const handleUpload = async () => {
    if (!video) {
      console.error("No video selected.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("shopId", shopId);
    formData.append("isVerified", "true");
    formData.append("shopreel", video, video.name);

    // Debugging step to ensure the FormData contains the correct values
    for (let [key, value] of formData.entries()) {
      if (key === "shopreel") {
        console.log(`${key}: ${value}`); // Display the file name
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    try {
      await axios.post(backendApiUrl + "/reels", formData).then((res) => {
        console.log(res);
        if (res.data.status === true) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
          setUploading(false);
        }
      });
    } catch (error) {
      console.error("Error creating banner:", error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography mt="4" align="center" variant="h3" fontWeight="700">
        Add Shop Reels
      </Typography>

      <Box align="center" sx={{ marginY: 40 }}>
        <Box style={{ marginTop: "50px" }}>
          {video && (
            <video
              style={{ width: "250px", marginLeft: "30px" }}
              controls
              src={URL.createObjectURL(video)}
              accept="video/*"
            />
          )}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <input
            width="100%"
            type="file"
            onChange={onVidChange}
            accept="video/*"
          />
          <Button
            variant="contained"
            disabled={!video || uploading}
            onClick={handleUpload}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
