import React, { Component } from "react";
import Selected from "./Selected";
import Thumbs from "./Thumbs";
import "./style.scss"; // Import your SCSS file

const images = [
  "https://unsplash.it/1000/600?image=1069",
  "https://unsplash.it/1000/600?image=1068",
  "https://unsplash.it/1000/600?image=1081",
  "https://unsplash.it/1000/600?image=855",
  "https://unsplash.it/1000/600?image=824",
  "https://unsplash.it/1000/600?image=655",
  "https://unsplash.it/1000/600?image=881",
  "https://unsplash.it/1000/600?image=314",
  "https://unsplash.it/1000/600?image=503",
  "https://unsplash.it/1000/600?image=447"
];

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    let loopedImages = images;
    while (loopedImages.length < 13) {
      loopedImages = loopedImages.concat(props.images);
    }
    this.state = {
      active: 0,
      showing: 0,
      phase: null,
      direction: "left",
      loopedImages: loopedImages
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.selectImage(1, 1);
    }, 1000);
  }

  selectImage(index, offset) {
    if (!this.state.phase && offset !== 0) {
      this.setState({
        active: index,
        phase: "entering",
        direction: offset >= 0 ? "left" : "right"
      });
      setTimeout(() => {
        this.setState({
          changing: false,
          showing: index,
          phase: "exiting"
        });
        setTimeout(() => {
          this.setState({ phase: null });
        }, 300);
      }, 400);
    }
  }

  render() {
    return (
      <div className="ImageViewer">
        <div className="ImageViewer__selected">
          <Selected
            src={this.state.loopedImages[this.state.showing]}
            phase={this.state.phase}
            direction={this.state.direction}
          />
        </div>
        <div className="ImageViewer__thumbs">
          <Thumbs
            images={this.state.loopedImages}
            active={this.state.active}
            select={this.selectImage.bind(this)}
            phase={this.state.phase}
            direction={this.state.direction}
          />
        </div>
      </div>
    );
  }
}

export default ImageViewer;
