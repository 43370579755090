import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close"; // Make sure you have @mui/icons-material installed

export default function ProductImages({ setPrevImg, prevImg }) {
  const imgDelete = (id) => {
    const newImageArray = prevImg.filter((_, i) => i !== id);
    newImageArray.map((img, index) => {
      ;
    });
    setPrevImg();
  };

  return (
    <>
      {prevImg && (
        <Box mx="1vh" justifyContent="center" display="flex">
          {prevImg.map((image) => (
            <div
              key={image.id}
              style={{
                position: "relative",
                display: "block",
                margin: "10px"
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "red",
                  cursor: "pointer",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "2px"
                }}
                onClick={() => imgDelete(image.id)}
              />
              <img width="200px" src={image.src} alt="ProductImage" />
            </div>
          ))}
        </Box>
      )}
    </>
  );
}
