import * as React from "react";
import { Box, Typography } from "@mui/material";
import ShopCard from "../Cards/ShopCard";
import Loader from "../../Elements/Loader/Loader";
import { getShops } from "../../../API/ShopApi";
import { useDispatch } from "react-redux";

export default function Home() {
  const [verify, setVerify] = React.useState([]);
  const [loading, setLoading] = React.useState("");
  const dispatch = useDispatch();

  const fetchData = async () => {
    // const response = await getAuthToken({
    //   mobile: "123456789",
    //   password: "1234"
    // }).then((e) => {
    //   dispatch(setAuthToken(e.data));
    // });
    // console.log(store.getState().auth.authToken);
    setLoading(true);
    getShops()
      .then((response) => {
        if (response.status === true) {
          setLoading(false);
        }
        return response.data;
      })
      .then((data) => {
        setVerify(data);
        console.log("data got it");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Typography variant="h3" fontWeight="600" marginTop="40px">
          Store List
        </Typography>
      </Box>
      {loading === "" || loading === true ? (
        <Loader isLoading={loading} />
      ) : (
        <Box
          sx={{
            width: "96%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            "& .MuiTableCell-root": { width: "2ch" },
            flexWrap: "wrap",
            position: "absolute",
            left: "4%",
            bgcolor: "white"
          }}>
          {verify.map((verified) => (
            <ShopCard
              key={verified._id}
              Shop_Name={verified.name}
              Karta_Name={verified.kartaName}
              Mobile={verified.mobile}
              imgUrl={verified.shopImg}
              id={verified._id}
            />
          ))}
        </Box>
      )}
    </>
  );
}
