import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import RoutesFunc from "../../../routes";

const PrivateRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log(isAuthenticated);
  const sessionFlag = sessionStorage.getItem("isAuthenticated");

  if (!isAuthenticated || !sessionFlag) {
    return <Navigate to="/" />;
  }

  return <RoutesFunc />;
};

export default PrivateRoute;
