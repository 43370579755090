/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import UnVerifiedProductCard from "./Cards/UnVerifiedProductCard";
import { getProducts } from "../../API/ProductApi";
export default function UnVerifiedProduct(props) {
  const [products, setProducts] = useState([]);
  const shopId = useParams();
  const fetchProducts = () => {
    getProducts()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setProducts(data.filter((d) => d.isVerified === false));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      {products.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "96vw",
            margin: "50px",
            justifyContent: "center",
            "& .MuiTableCell-root": { width: "1ch" },
            flexWrap: "wrap"
          }}
        >
          <Typography variant="h3">Un-Verified Product</Typography>

          {products.map((product) => (
            <UnVerifiedProductCard
              Product_Name={product.name}
              Product_Desc={product.desc}
              Price={product.price}
              imgUrl={product.imageURLs}
              productThumbnail={product.productThumbnail}
              id={product._id}
              shopId={shopId}
            />
          ))}
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Box>
            <Typography
              align="center"
              marginBottom="170px"
              fontWeight="600"
              variant="h3"
              style={{ zIndex: "3" }}
            >
              Un-Verified Product
            </Typography>
            <Box display="flex" justifyContent="right"></Box>

            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link to={`/admin/verifiedShopsList/addProduct/${shopId}`}>
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
