import React, { useState, useEffect } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Quill.css";

const QuillEditor = (props) => {
  const { value, onStateChange } = props;
  const [editorState, setEditorState] = useState(() => {
    if (props.value && props.value.desc) {
      const blocksFromHTML = convertFromHTML(props.value.desc);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  useEffect(() => {
    if (value) {
      onStateChange({
        ...value,
        desc: stateToHTML(editorState.getCurrentContent())
      });
      console.log("hello");
    }
  }, [editorState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  return (
    <div className="Editor">
      <h3 style={{ textAlign: "left" }}>Description *</h3>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
    </div>
  );
};


export default QuillEditor;
