import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Container,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import axios from "axios";
import LocationPicker from "../Elements/LocationPicker/LocationPicker";
import { getShopById } from "../../API/ShopApi";
import { backendApiUrl } from "../../constants";
import { toast } from "react-toastify";

export default function VerifiedShopTemplate() {
  const [img, setImg] = useState({ preview: "", raw: "" });
  const [bgImg, setBgImg] = useState({ preview: "", raw: "" });
  const { id } = useParams();
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [user, setUser] = useState({
    name: "",
    kartaName: "",
    aadharNo: "",
    password: "",
    panNo: "",
    desc: "",
    gstNo: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    shopImg: "",
    shopBgImg: "",
    location: { coordinates: coordinates, type: "point" },
    email: "",
    websiteLink: "",
    socialMediaLinks: [],
  });

  const [isChoosingLocation, setIsChoosingLocation] = useState(false);
  const iconButtonRef = useRef(null);

  const defaultSocialMediaLinks = [
    { type: "instagram", url: "" },
    { type: "whatsapp", url: "" },
    { type: "facebook", url: "" },
  ];

  useEffect(() => {
    if (user.socialMediaLinks.length !== 3) {
      const updatedLinks = defaultSocialMediaLinks.map((defaultLink) => {
        const existingLink = user.socialMediaLinks.find(
          (link) => link.type === defaultLink.type
        );
        return existingLink || defaultLink;
      });
      setUser((prevUser) => ({
        ...prevUser,
        socialMediaLinks: updatedLinks,
      }));
    }
  }, [user.socialMediaLinks]);

  const imageChangeHandler = (e, setImage) => {
    if (e.target.files.length && e.target.files[0].size <= 3 * 1024 * 1024) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    } else {
      toast.warning("Image is too large, please keep it below 3MB");
    }
  };

  useEffect(() => {
    if (iconButtonRef.current && !isChoosingLocation) {
      iconButtonRef.current.click();
    }
  }, [isChoosingLocation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append form fields
    Object.entries(user).forEach(([key, value]) => {
      if (
        [
          "name",
          "email",
          "kartaName",
          "password",
          "desc",
          "aadharNo",
          "panNo",
          "gstNo",
          "mobile",
          "address",
          "state",
          "city",
          "pincode",
          "location",
          "socialMediaLinks",
        ].includes(key)
      ) {
        if (key === "location") {
          formData.append("location[0]", coordinates.lat || user.location[0]);
          formData.append("location[1]", coordinates.lng || user.location[1]);
        } else if (key === "socialMediaLinks") {
          let index = 0;
          user.socialMediaLinks.forEach((link) => {
            if (link && link.url) {
              formData.append(`socialMediaLinks[${index}][type]`, link.type);
              formData.append(`socialMediaLinks[${index}][url]`, link.url);
              index++;
            }
          });
        } else {
          if (value) {
            formData.append(key, value);
          }
        }
      }
    });

    // Append images
    formData.append("shopImage", img.raw || user.shopImg);
    formData.append("shopBgImage", bgImg.raw || user.shopBgImg);

    try {
      const response = await axios.put(
        `${backendApiUrl}/shops/${id}`,
        formData
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error("Error submitting form");
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getShopById(id);
      const data = response.data;
      setUser(data);
      setCoordinates(data.location.coordinates);
    } catch (error) {
      console.error("Error fetching shop data", error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSocialMediaChange = (index, value) => {
    const updatedLinks = [...user.socialMediaLinks];
    updatedLinks[index].url = value;
    setUser((prevUser) => ({
      ...prevUser,
      socialMediaLinks: updatedLinks,
    }));
  };

  const handleChooseLocation = () => {
    setIsChoosingLocation(true);
  };

  const handleLocationSelect = (location) => {
    setCoordinates(location);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          padding="20px"
          align="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "fit-content",
            width: "100%",
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}>
          <Container maxWidth="lg">
            <Typography variant="h3" marginBottom="5vh">
              {user.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid item sm={12} lg={6}>
                <Box
                  className="wrapper"
                  sx={{
                    backgroundColor: "black",
                    position: "relative",
                    border: "5px solid #fff",
                    borderRadius: "10%",
                    width: "500px",
                    height: "470px",
                  }}>
                  {bgImg.preview ? (
                    <img
                      height="500px"
                      width="500px"
                      src={bgImg.preview}
                      alt="Shop Background"
                      className="Pimage"
                    />
                  ) : (
                    <img
                      height="500px"
                      width="500px"
                      src={`${user.shopBgImg}`}
                      alt="Shop Background"
                    />
                  )}
                  <input
                    type="file"
                    className="onClickBg"
                    onChange={(e) => imageChangeHandler(e, setBgImg)}
                  />
                  <Box
                    className="wrapper"
                    sx={{
                      backgroundColor: "black",
                      position: "absolute",
                      border: "5px solid #fff",
                      borderRadius: "10%",
                      width: "170px",
                      height: "170px",
                      top: "160px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      overflow: "hidden",
                      zIndex: "20",
                    }}>
                    {img.preview ? (
                      <img
                        height="190vh"
                        src={img.preview}
                        alt="Shop"
                        className="Pimage"
                      />
                    ) : (
                      <img
                        height="190vh"
                        src={`${user.shopImg}`}
                        alt="Shop"
                        style={{ width: "100%" }}
                      />
                    )}
                    <input
                      type="file"
                      className="onClick"
                      onChange={(e) => imageChangeHandler(e, setImg)}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  marginTop="5vh">
                  <Button
                    href={`/#/admin/verifiedShopsList/showProducts/${id}`}>
                    Show All Products
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box marginY="2vh">
                  <Grid container spacing={3}>
                    {[
                      "name",
                      "email",
                      "kartaName",
                      "password",
                      "desc",
                      "aadharNo",
                      "panNo",
                      "gstNo",
                      "mobile",
                      "address",
                      "state",
                      "city",
                      "pincode",
                    ].map((field) => (
                      <Grid item lg={6} key={field}>
                        <TextField
                          size="medium"
                          name={field}
                          label={field.replace(/^\w/, (c) => c.toUpperCase())}
                          required={
                            field !== "aadharNo" &&
                            field !== "panNo" &&
                            field !== "gstNo" &&
                            field !== "desc" &&
                            field !== "location"
                          }
                          value={user[field]}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Typography variant="h6" marginTop="2vh">
                        Social Media Links
                      </Typography>
                      {user.socialMediaLinks.map((link, index) => (
                        <TextField
                          key={link ? link.type : index}
                          label={
                            link && link.type
                              ? link.type.charAt(0).toUpperCase() +
                                link.type.slice(1)
                              : ""
                          }
                          value={link ? link.url : ""}
                          onChange={(e) =>
                            handleSocialMediaChange(index, e.target.value)
                          }
                          fullWidth
                          sx={{ marginBottom: "1vh" }}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" marginTop="2vh">
                        Website Link
                      </Typography>
                      <TextField
                        name="websiteLink"
                        label="Website"
                        value={user.websiteLink}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          height: "fit",
                          width: "auto",
                          overflow: "hidden",
                        }}>
                        {isChoosingLocation ? (
                          <LocationPicker
                            location={user.location}
                            setSelection={setIsChoosingLocation}
                            onSelectLocation={handleLocationSelect}
                          />
                        ) : (
                          <IconButton
                            variant="contained"
                            onClick={handleChooseLocation}
                            ref={iconButtonRef}>
                            <AddLocationIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ "& .MuiButton-root": { m: 1 } }}>
                        <Button
                          variant="contained"
                          type="submit"
                          startIcon={<UpdateIcon />}>
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </>
  );
}
