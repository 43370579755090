import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { deleteChildCategory } from "../../../API/ChildCategoryApi";
import { toast } from "react-toastify";

const Delete = (props) => {
  const erase = () => {
    deleteChildCategory(props.id)
      .then((response) => {
        if (response.status === true) {
          toast.success(`${response.message}`);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
      });
  };
  return (
    <Button onClick={() => erase()} variant={props.variant} color="error">
      Delete
    </Button>
  );
};

export default Delete;
