// CategoryApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const ReelsApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Add an interceptor to include the token in the Authorization header for all requests
ReelsApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your category-related API endpoints
export const getAllReels = async () => {
  try {
    const response = await ReelsApi.get("/reels/random");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllReelsByShopId = async (id) => {
  try {
    const response = await ReelsApi.get(`/reels/${id}/shop/reelInfo`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllVerifiedReelsByShopId = async (id) => {
  try {
    const response = await ReelsApi.get(`/reels/${id}/shop/verified`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUnverifiedReelsByShopId = async (id) => {
  try {
    const response = await ReelsApi.get(`/reels/${id}/shop/unverified`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateReel = async (id) => {
  try {
    const response = await ReelsApi.put(`/reels/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createReels = async (reelsData) => {
  try {
    const response = await ReelsApi.post("/reels", reelsData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteReels = async (id) => {
  try {
    const response = await ReelsApi.delete(`/reels/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other category-related API endpoints as needed

export default ReelsApi;
