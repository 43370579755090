import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  videoStyles: {
    height: "60vh",
    width: "100%",
    background:
      "linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)",
    overflowY: "scroll", // Enable vertical scrolling
    scrollSnapType: "y mandatory", // Snap to each section vertically
    scrollBehavior: "smooth", // Smooth scrolling behavior

    // Optional: Hide scrollbar
    "&::-webkit-scrollbar": {
      display: "none" // Hide scrollbar for WebKit (Safari/Chrome)
    },
    scrollbarWidth: "none" // Hide scrollbar for Firefox
  }
});
