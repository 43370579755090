import React, { useState, useEffect } from "react";
import axios from "axios";
import MapPicker from "react-google-map-picker";
import { MapPickerApiToken } from "../../../constants";

const LocationPickerWithSearch = ({ onSelectLocation, location }) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (location) {
      setDefaultLocation(location);
      onSelectLocation({ lat: location[0], lng: location[1] });
    }
  }, [location]);

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          query
        )}&key=${MapPickerApiToken}`
      );
      if (response.data.status === "OK") {
        setSearchResults(response.data.results);
      } else {
        console.error("Geocoding API error:", response.data.error_message);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleLocationSelect = (location) => {
    console.log(location);
    setSelectedLocation(location.geometry.location);
    onSelectLocation(location.geometry.location);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search for location"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      <ul>
        {searchResults.map((result) => (
          <li key={result.place_id}>
            <button onClick={() => handleLocationSelect(result)}>
              {result.formatted_address}
            </button>
          </li>
        ))}
      </ul>
      <MapPicker
        defaultLocation={defaultLocation}
        zoom={10}
        style={{ height: "400px", width: "100%" }}
        onChangeLocation={(location) => setSelectedLocation(location)}
        apiKey={MapPickerApiToken}
      />
      {selectedLocation && (
        <div>
          <h3>Selected Location:</h3>
          <p>Latitude: {selectedLocation.lat}</p>
          <p>Longitude: {selectedLocation.lng}</p>
        </div>
      )}
    </div>
  );
};

export default LocationPickerWithSearch;
