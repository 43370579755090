/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  TextField,
  Button,
  Autocomplete,
  Typography,
  IconButton,
  Modal,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import LocationPicker from "../Elements/LocationPicker/LocationPicker";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { createShopApi } from "../../API/ShopApi";
import { getProductCategories } from "../../API/CategoryApi";
import ImageCropper from "../Elements/Cropper/ImageCropper";
import { set } from "lodash";
import { toast } from "react-toastify";
export default function CreateShop() {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const [img, setImg] = useState({ preview: "", raw: "" });
  const [category, setCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isChoosingLocation, setIsChoosingLocation] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [bgImg, setBgImg] = useState({ preview: "", raw: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [linkType, setLinkType] = useState("instagram");
  const [shopId, setShopId] = useState("");
  const [link, setLink] = useState({
    instagram: "",
    whatsApp: "",
    facebook: "",
    website: "",
  });
  const chooseLinkType = [
    {
      value: "instagram",
      label: "Instagram",
    },
    {
      value: "whatsApp",
      label: "WhatsApp",
    },
    {
      value: "facebook",
      label: "Facebook",
    },
    {
      value: "website",
      label: "Website",
    },
  ];
  const handleLinkType = (event) => {
    setLinkType(event.target.value);
  };

  const handleLink = (event) => {
    setLink({ ...link, [linkType]: event.target.value });
  };
  const fetchCategories = async () => {
    try {
      const response = await getProductCategories();
      const data = response.data;
      setCategory(data);
      console.log(category, response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    console.log(link);
  }, [link]);

  const handleChooseLocation = () => {
    setIsChoosingLocation(true);
  };

  const handleLocationSelect = (location) => {
    setCoordinates(location);
    console.log(coordinates);
    setIsChoosingLocation(false);
  };

  const onImageChange = (e) => {
    setImg({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
    // handleOpen();
  };

  const onImageChange1 = (e) => {
    if (e.target.files.length) {
      setBgImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
    // handleOpen();
  };

  useEffect(() => {
    console.log("hello", bgImg);
  }, [bgImg]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSubmit(true);
    try {
      const formData = new FormData();
      formData.append("shopImage", img.raw);
      formData.append("shopBgImage", bgImg.raw);
      if (!coordinates.lat || !coordinates.lng) {
        formData.append("location[]", []);
      } else {
        formData.append("location[0]", coordinates.lat);
        formData.append("location[1]", coordinates.lng);
      }
      formData.append("itemCategories[0]", selectedCategories);
      formData.append("name", data.name);
      formData.append("desc", data.desc);
      formData.append("kartaName", data.kartaName);
      formData.append("email", data.email);
      formData.append("aadharNo", data.aadharNo);
      formData.append("panNo", data.panNo);
      formData.append("gstNo", data.gstNo);
      formData.append("mobile", data.mobile);
      formData.append("password", data.password);
      formData.append("address", data.address);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("pincode", data.pincode);
      formData.append("isVerified", true);
      if (link.instagram) {
        formData.append("socialMediaLinks[0][type]", "instagram");
        formData.append("socialMediaLinks[0][url]", link.instagram);
      }
      if (link.whatsApp) {
        formData.append("socialMediaLinks[1][type]", "whatsApp");
        formData.append("socialMediaLinks[1][url]", link.whatsApp);
      }
      if (link.facebook) {
        formData.append("socialMediaLinks[2][type]", "facebook");
        formData.append("socialMediaLinks[2][url]", link.facebook);
      }
      if (link.website) {
        formData.append("websiteLink", link.website);
      }

      const response1 = await createShopApi(formData);

      if (response1.status === true) {
        setSubmit(false);
        toast.success(response1.message);
        setShopId(response1.data._id);
      } else {
        toast.error(response1.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmit(false);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{ paddingBlock: "30px" }}
          sx={{
            display: "grid",
            justifyContent: "center",
            height: "auto",
            width: "100%",
            "& .MuiTextField-root": { m: 1, width: "65ch" },
          }}>
          <h1 style={{ textAlign: "center" }}>Create your shop</h1>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}>
            <TextField
              name="name"
              label="Shop Name"
              title="Shop Name"
              required
              error={!!errors.name}
              helperText={errors.name?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 3, // Minimum length of 3 characters
                    message: "Name must be at least 3 characters",
                  },
                }),
              }}
            />

            <TextField
              name="kartaName"
              required
              type="text"
              label="Karta Name"
              title="Karta Name"
              error={!!errors.kartaName}
              helperText={errors.kartaName?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("kartaName", {
                  required: "Karta Name is required",
                  minLength: {
                    value: 3, // Minimum length of 3 characters
                    message: "Karta Name must be at least 3 characters",
                  },
                }),
              }}
            />
            <TextField
              name="desc"
              label="Shop description"
              error={!!errors.desc}
              title="Shop Description"
              helperText={errors.desc?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("desc", {
                  required: "Description is required",
                  minLength: {
                    value: 3, // Minimum length of 3 characters
                    message: "Description must be at least 3 characters",
                  },
                }),
              }}
            />

            <TextField
              name="email"
              required
              type="text"
              label="Email"
              title="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // Basic email pattern
                    message: "Invalid email address",
                  },
                }),
              }}
            />

            <TextField
              name="password"
              type="password" // Change to "password" type for password fields
              required
              label="Password"
              title="Password"
              error={!!errors.password}
              helperText={errors.password?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8, // Minimum length of 8 characters
                    message: "Password must be at least 8 characters",
                  },
                }),
              }}
            />

            <TextField
              name="aadharNo"
              label="Aadhar Number"
              type="number"
              title="Aadhar Number"
              error={!!errors.aadharNo}
              helperText={errors.aadharNo?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("aadharNo", {
                  // required: "Aadhar Number is required",
                  pattern: {
                    value: /^[0-9]{12}$/, // Aadhar number must be 12 digits
                    message: "Aadhar number must be 12 digits",
                  },
                }),
              }}
            />

            <TextField
              name="panNo"
              label="PAN Number"
              title="PAN Number"
              error={!!errors.panNo}
              helperText={errors.panNo?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("panNo", {
                  // required: "PAN Number is required",
                  pattern: {
                    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, // Valid PAN format
                    message: "Invalid PAN number",
                  },
                }),
              }}
            />

            <TextField
              name="gstNo"
              label="GST Number"
              title="GST Number"
              error={!!errors.gstNo}
              helperText={errors.gstNo?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("gstNo", {
                  // required: "GST Number is required",
                  pattern: {
                    value: /^[0-9A-Z]{15}$/, // Valid GST format
                    message: "Invalid GST number",
                  },
                }),
              }}
            />

            <TextField
              name="mobile"
              label="Mobile Number"
              type="number"
              title="Mobile Number"
              error={!!errors.mobile}
              helperText={errors.mobile?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("mobile", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^[0-9]{10}$/, // Mobile number must be 10 digits
                    message: "Mobile number must be 10 digits",
                  },
                }),
              }}
            />

            <TextField
              name="address"
              required
              label="Address"
              title="Address"
              error={!!errors.address}
              helperText={errors.address?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("address", {
                  required: "Address is required",
                }),
              }}
            />

            <TextField
              name="state"
              required
              label="State"
              title="State"
              error={!!errors.state}
              helperText={errors.state?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("state", {
                  required: "State is required",
                }),
              }}
            />

            <TextField
              name="city"
              required
              label="City"
              title="City"
              error={!!errors.city}
              helperText={errors.city?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("city", {
                  required: "City is required",
                }),
              }}
            />

            <TextField
              name="pincode"
              required
              label="Pin Code"
              type="number"
              title="Pin Code"
              error={!!errors.pincode}
              helperText={errors.pincode?.message}
              inputProps={{
                // Apply schema validation rules
                ...register("pincode", {
                  required: "Pin Code is required",
                  pattern: {
                    value: /^[0-9]{6}$/, // Pin code must be 6 digits
                    message: "Pin code must be 6 digits",
                  },
                }),
              }}
            />

            {/* Social Media Link */}
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="instagram"
              helperText="Please select type of link"
              onChange={handleLinkType}>
              {chooseLinkType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {/* Social Media Link */}
            <TextField
              name="link"
              label="Link"
              title="Link"
              onChange={handleLink}
              helperText={`Please enter your ${linkType} link`}
            />

            <Autocomplete
              id="tags-outlined"
              options={category}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                setSelectedCategories(value ? value._id : "");
              }}
              filterSelectedOptions
              value={
                category && category.length > 0
                  ? category.find((cat) => cat._id === selectedCategories)
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  title="Category"
                  label="Category"
                  required
                  placeholder="Select More"
                  error={!!errors.category}
                  helperText={errors.category?.message}
                />
              )}
            />
          </div>
          <div
            display="block"
            align="center"
            style={{
              marginTop: 24,
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography>Shop Image *</Typography>
            <input
              accept="image/*"
              title="Shop Image"
              type="file"
              {...register("shopImg", { required: "Shop Image is required" })}
              style={{ marginBottom: "10px" }}
              onChange={onImageChange}
            />
            <Box>
              {img.preview && <img width="100vh" src={img.preview} alt="." />}
            </Box>
            <Modal
              style={{ overflow: "scroll" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <ImageCropper
                images={[img.preview]}
                setImages={setImg}
                title="Crop Image"
                onSaveImages={handleClose}
                multi={"false"}
              />
            </Modal>
            {errors.shopImg && (
              <p style={{ color: "red" }}>{errors.shopImg.message}</p>
            )}
            <Typography>Shop Background Image</Typography>
            <input
              accept="image/*"
              type="file"
              title="Shop Background Image"
              {...register("shopBgThumbnail", {
                required: "Shop Image is required",
              })}
              style={{ marginBottom: "10px" }}
              onChange={onImageChange1}
            />
            <img src={bgImg.preview} width="100px" />

            {/* <Modal
              style={{ overflow: "scroll" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ImageCropper
                images={[bgImg.preview]}
                setImages={setBgImg}
                onSaveImages={handleClose}
                multi={"false"}
              />
            </Modal> */}
            {errors.shopImg && (
              <p style={{ color: "red" }}>{errors.shopImg.message}</p>
            )}

            {isChoosingLocation ? (
              <>
                <Typography>Choose Location</Typography>
                <LocationPicker onSelectLocation={handleLocationSelect} />
              </>
            ) : (
              <>
                <Typography>Choose Location</Typography>
                <IconButton
                  color={coordinates.length > 0 ? "success" : "default"}
                  onClick={handleChooseLocation}>
                  <AddLocationIcon />
                </IconButton>
              </>
            )}
          </div>
          {shopId && (
            <Box
              align="center"
              sx={{ marginTop: 3, "& .MuiButton-root": { m: 1 } }}>
              <Typography variant="h6" marginTop="2vh">
                Vist this{" "}
                <a href={`/#/admin/verifiedShopsList/${shopId}`}>link</a> to
                update
              </Typography>
            </Box>
          )}
          <Box
            align="center"
            sx={{ marginTop: 3, "& .MuiButton-root": { m: 1 } }}>
            <Button variant="contained" color="success" type="submit">
              Create
            </Button>
          </Box>
        </Box>
      </form>
      {submit ? (
        <div style={{ zIndex: 3, display: "flex", backfaceVisibility: "70%" }}>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
