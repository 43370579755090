import "./App.css";
import { Routes, Route } from "react-router-dom";
import SignIn from "./Components/Views/LogIn/LogIn";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import PrivateRoute from "./Components/Views/Protected/PrivateRoute.js";
import NewRequestList from "./Components/Views/NewRequestsList";
import ShopTemplate from "./Components/Views/ShopTemplate";
import Enquiries from "./Components/Views/Enquiries";
import VerifiedShopTemplate from "./Components/Views/VerifiedShopTemplate";
import ProductList from "./Components/Views/ProductList";
import Product from "./Components/Views/Product";
import UnVerifiedProduct from "./Components/Views/UnVerifiedProduct";
import AddProduct from "./Components/Views/AddProduct";
import ShopBanner from "./Components/Views/ShopBanner";
import TestComponent from "./Components/Elements/TestComponent/TestComponent";
import Categories from "./Components/Views/Category/Categories";
import ProductImages from "./Components/Views/ProductImages";
import BlockedShops from "./Components/Views/BlockedShops";
import CreateShop from "./Components/Views/AddNewShop";
import Reels from "./Components/Views/Reels/Reels";
import Home from "./Components/Views/Home/Home";
import ShopDetails from "./Components/Views/ShopDetails.js";
import AddShopReels from "./Components/Views/AddShopReels";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route exact path="/" element={<SignIn />}></Route>

            <Route path="/admin/" element={<PrivateRoute />}>
              <>
                <Route path="newRequests" element={<NewRequestList />} />
                <Route path="newRequests/:id" element={<ShopTemplate />} />
                <Route path="enquiries" element={<Enquiries />} />
                <Route
                  path="verifiedShopsList/:id"
                  element={<VerifiedShopTemplate />}
                />
                <Route
                  path="verifiedShopsList/showProducts/:shopId"
                  element={<ProductList />}
                />
                <Route
                  path="verifiedShopsList/showProducts/:shopId/:id"
                  element={<Product />}
                />
                <Route
                  path="verifiedShopsList/UnVerifiedProducts"
                  element={<UnVerifiedProduct />}
                />
                <Route
                  path="verifiedShopsList/shopDetails/:shopId"
                  element={<ShopDetails />}
                />
                <Route
                  path="verifiedShopsList/addProduct/:shopId"
                  element={<AddProduct />}
                />
                <Route
                  path="verifiedShopsList/addShopReels/:shopId"
                  element={<AddShopReels />}
                />
                <Route
                  path="verifiedShopsList/addShopBanner/:shopId"
                  element={<ShopBanner />}
                />
                <Route path="test" element={<TestComponent />} />
                <Route path="categories" element={<Categories />} />
                <Route
                  path="showProductsImages/:shopId/:id"
                  element={<ProductImages />}
                />
                <Route path="blockedShops" element={<BlockedShops />} />
                <Route path="createShop" element={<CreateShop />} />
                <Route path="reels" element={<Reels />} />
                <Route path="*" element={<Home />} />
              </>
            </Route>
          </Routes>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
