import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField, Modal, Typography } from "@mui/material";
import DeleteChildCategory from "../Delete/DeleteChildCategory";
import {
  getProductChildCategories,
  editChildCategoryById,
} from "../../../API/ChildCategoryApi";

const ChildCategoryList = (props) => {
  const [category, setCategory] = useState([]);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = props;

  const fetchChildCategories = async () => {
    try {
      const data = await getProductChildCategories();
      if (Array.isArray(data.data)) {
        const filteredData = data.data.filter((d) => d.subCategoryId === id);
        setCategory(filteredData);
      } else {
        console.error("Fetch category error: Data is not an array");
      }
    } catch (error) {
      console.error("Fetch category error:", error);
    }
  };

  useEffect(() => {
    fetchChildCategories();
  }, [id]);

  const handleEditClick = (cat) => {
    setEditCategoryData(cat);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditCategoryData(null);
  };

  const handleInputChange = (e) => {
    setEditCategoryData({
      ...editCategoryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    if (editCategoryData) {
      try {
        const formData = new FormData();
        formData.append("_id", editCategoryData._id);
        formData.append("name", editCategoryData.name);

        await editChildCategoryById(editCategoryData._id, formData);

        fetchChildCategories();
        handleModalClose();
      } catch (error) {
        console.error("Update category error:", error);
      }
    }
  };

  return (
    <>
      {category.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table>
              {category.map((cat) => (
                <TableRow key={cat._id}>
                  <TableCell component="th" scope="row">
                    {cat.name}
                  </TableCell>
                  <TableCell sx={{ display: "flex", padding: "0" }}>
                    <Button
                      sx={{ mr: 1 }}
                      variant="contained"
                      onClick={() => handleEditClick(cat)}>
                      Edit
                    </Button>
                    <DeleteChildCategory variant="text" id={cat._id} />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Edit Child Category Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="edit-child-category-title"
        aria-describedby="edit-child-category-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography
            id="edit-child-category-title"
            variant="h6"
            component="h2">
            Edit Child Category
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Child Category Name"
            name="name"
            value={editCategoryData ? editCategoryData.name : ""}
            onChange={handleInputChange}
          />
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
              sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ChildCategoryList;
